.primary {
    background-color: #{$primary-color};
    &.lighten {
        background-color: #{$primary-background-color};
    }
}

.secondary {
    background-color: #{$secondary-color};
    &.lighten {
        background-color: #{$secondary-background-color};
    }
}

.accent {
    background-color: #{$accent-color};
    &.lighten {
        background-color: #{$accent-background-color};
    }
}
.primary-text {
    color: #{$primary-color};
}

.secondary-text {
    color: #{$secondary-color};
}

.accent-text {
   color: #{$accent-color};
}

.base {
    background-color: #{$background-color};
}

.base-text {
    color: #{$text-color}
}

.bi-primary-color {
    background-image: linear-gradient(transparent 50%, #{$primary-background-color} 50%);
}

.bi-secondary-color {
    background-image: linear-gradient(transparent 50%, #{$secondary-background-color} 50%);
}

.bi-accent-color {
    background-image: linear-gradient(transparent 50%, #{$accent-background-color} 50%);
}

.white-text {
    color: #FFFFFF;
    a {
        color: #FFFFFF;
    }
}